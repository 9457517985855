import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './CourtDetails.css';
import { courtsData } from '../CourtComponent/Courts';
import Form from '../FormComponent/Form';
import { BsArrowsFullscreen } from "react-icons/bs";
import { PiCourtBasketballFill } from "react-icons/pi";


const CourtDetails = () => {
  const { id } = useParams();
  const court = courtsData.find(court => court.id === parseInt(id));

  const [currentImage, setCurrentImage] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);

  const handleImageClick = (index) => {
    setCurrentImage(index);
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleNextImage = () => {
    if (currentImage < court.courtImages.length - 1) {
      setCurrentImage(currentImage + 1);
    } else {
      setCurrentImage(0);
    }
  };

  const handlePreviousImage = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    } else {
      setCurrentImage(court.courtImages.length - 1);
    }
  };

  const handleOutsideClick = (event) => {
    if (event.target.className === 'popup') {
      handleClosePopup();
    }
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        handleClosePopup();
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  if (!court) {
    return <div>Court not found</div>;
  }

  return (
    <div className="court-details2">
      <div className="court-gallery">
        <div className="main-image-container">
          <img 
            src={court.courtImages[0]} 
            alt="Main Court" 
            className="main-image" 
            onClick={() => handleImageClick(0)} 
          />
          <button className="popup-button" onClick={() => handleImageClick(0)}>
            <span className='viewPhotos'>View all photos </span> {<BsArrowsFullscreen className='fullscreen'/>}
          </button>
        </div>
        <div className="small-images">
          {court.courtImages.slice(1, 5).map((image, index) => (
            <img 
              key={index} 
              src={image} 
              alt={`Court ${index + 2}`} 
              onClick={() => handleImageClick(index + 1)} 
            />
          ))}
        </div>
      </div>
      <div className="court-info-container">
        <div className="court-info">
          <h2>Hosted By {court.details.host}</h2>
          <div className="court-rating">
            {/* <span className="star">★</span> {5} · {"Star"} · {"47"} review{court.reviews !== 1 ? 's' : ''}  */}
          </div>
          <div className="court-details">
            <div className="detail-item">
              <h1 className="icon">⛹🏼</h1>
              <div>
                <strong>Number of courts</strong>
                <p>{court.details.numberOfCourts}.</p>
                <p>{court.details.capacity} people limit in facility.</p>
              </div>
            </div>
            <div className="detail-item">
              <h1 className="icon">🚽</h1>
              <div>
                <strong>Restroom available</strong>
                <p>Dedicated restroom available by the court</p>
              </div>
            </div>
            <div className="detail-item">
              <h1 className="icon">🅿️</h1>
              <div>
                <strong>Parking</strong>
                <p>Up to {court.details.parking} cars on site.</p>
              </div>
            </div>
            <div className="detail-item">
              <h1 className="icon">📅</h1>
              <div>
                <strong>Free cancellation!</strong>
                <p>Get a full refund if you cancel 1 day before your booking.</p>
              </div>
            </div>

            <div className="detail-item">
              <h1 className="icon">👨🏽‍💼</h1>
              <div>
                <strong>Requires Insurance?</strong>
                <p>You must obtain insurance in order to reserve this court. Can't find an insurance? We got you coverd! Make sure to enter Yes for Need Insurance field.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="booking-form">
          <Form />
        </div>
      </div>
      {popupOpen && (
        <div className="popup" onClick={handleOutsideClick}>
          <button className="close-button" onClick={handleClosePopup}>X</button>
          <button className="nav-button back-button" onClick={handlePreviousImage}>{"<"}</button>
          <button className="nav-button forward-button" onClick={handleNextImage}>{">"}</button>
          <img src={court.courtImages[currentImage]} alt={`Court ${currentImage + 1}`} />
        </div>
      )}
    </div>
  );
};

export default CourtDetails;
