import React from 'react';
import './ContactUs.css'; // Ensure this line is present to import the new CSS
import { CiMail } from "react-icons/ci";

const ContactUs = () => {
  const handleContactClick = () => {
    const email = 'mohammadkhizar61@gmail.com';
    const subject = 'Contact Us Inquiry';
    const body = 'Hello, I have a question...'; // You can customize the default email body here

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  return (
    <div className="contact-us-container">
        <h2>More Courts Coming Soon...👀</h2>
        <button onClick={handleContactClick} className="contact-us-button">Get In Touch </button> <CiMail onClick={handleContactClick} className='iconn'/>
    </div>
  );
};

export default ContactUs;
