import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import './Form.css';
import axios from 'axios';

const Form = () => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    function Submit(e) {
        e.preventDefault();
        const formEle = document.querySelector("form");
        const formData = new FormData(formEle);
        
        axios.post('http://localhost:8000/submit-form', Object.fromEntries(formData))
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error('Network response was not ok');
                }
                setSubmitted(true);
            })
            .catch((error) => {
                console.error('There was a problem with the submission:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSubmit = (e) => {
        setLoading(true);
        Submit(e);
    };

    const handleSubmitAnother = () => {
        setSubmitted(false);
    }

    return (
        <div className="form-container">
            {submitted ? (
                <div className="success-message form-group form-group2" >
                    <p>We've received your request 🙌🏼 We'll be in touch soon to confirm your reservation. Stay tuned! 📲</p>
                    <Button variant="primary" name="Name" type="submit" onClick={handleSubmitAnother}>
                        {loading ? 'Submitting...' : "Submit Another"}
                    </Button>
                </div>
            ) : (
                <React.Fragment>
                    <h2>Reserve Now</h2>
                    <form className="form" onSubmit={(e) => handleSubmit(e)}>
                        <div className='form-group'>
                            <input placeholder="Full Name" name="Name" type="text" required />     
                        </div>
                        <div className='form-group'>
                            <input placeholder="Email" name="Email" type="text" required />
                        </div>
                        <div className='form-group'>
                            <input placeholder="Number of Attendees" name="Attendees" type="text" required />
                        </div>
                        <div className='form-group'>
                            <input placeholder="Phone Number" name="Number" type="text" required />
                        </div>
                        <div className='form-group'>
                            <input placeholder="Need Insurance?" name="NeedInsurance" type="text" required />
                        </div>
                        <div className='form-group'>
                            <input placeholder="Date" name="Time" type="text" required />
                        </div>
                        <div className='form-group'>
                            <input placeholder="Time: 7pm-10pm" name="Duration" type="text" required />
                        </div>
                        <div className="form-group">
                            <Button variant="primary" name="Name" type="submit">{loading ? 'Submitting...' : "Submit"}</Button>
                        </div>
                    </form>
                </React.Fragment>
            )}
        </div>
    );
}

export default Form;