import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Courts.css';
import one from '../one.JPG';
import two from '../two.JPG';
import three from '../three.JPG';
import Modal from '../ModalComponent/Modal';
import ContactUs from '../ContactUsComponent/ContactUs';

export const courtsData = [
  {
    id: 1,
    name: 'Court 1',
    location: 'Redwood High School',
    desc: 'Redwood City',
    imageUrl: one,
    courtImages: [one, two, three, one, two],
    details: {
      location: 'Redwood City, CA',
      capacity: '200',
      price: '$100/hr',
      numberOfCourts: '2 full courts, 1 half court',
      features: 'Has seating for family and audience to come.',
      parking: 'Parking is available in the parking lot.',
      host: "Redwood High"
    }
  },
  {
    id: 2,
    name: 'Court 2',
    location: 'Woodside High School',
    desc: 'Mountain View',
    imageUrl: two,
    courtImages: [three, one, two],
    details: {
      location: 'Mountain View, CA',
      capacity: '200',
      price: '$100/hr',
      numberOfCourts: '2 full courts, 1 half court',
      features: 'Has seating for family and audience to come.',
      parking: 'Parking is available in the parking lot.',
      host: "Woodside High"
    }
  },
  {
    id: 3,
    name: 'Court 3',
    location: 'Hamilton High School',
    desc: 'Palo Alto',
    imageUrl: three,
    courtImages: [three, one, two],
    details: {
      location: 'Palo Alto, CA',
      capacity: '200',
      price: '$100/hr',
      numberOfCourts: '2 full courts, 1 half court',
      features: 'Has seating for family and audience to come.',
      parking: 'Parking is available in the parking lot.',
      host: "Alexander Hamilton High"
    }
  },
  {
    id: 4,
    name: 'Court 4',
    location: 'Culver High School',
    desc: 'Culver City',
    imageUrl: one,
    courtImages: [three, one, two],
    details: {
      location: 'Culver City, CA',
      capacity: '200',
      price: '$100/hr',
      numberOfCourts: '2 full courts, 1 half court',
      features: 'Has seating for family and audience to come.',
      parking: 'Parking is available in the parking lot.',
      host: "Culver City High"
    }
  }
];


const Court = ({ id, name, imageUrl, courtImages, desc }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleBookNow = (event) => {
    event.stopPropagation();
    navigate(`/court-details/${id}`);
  };

  return (
    <div className="court form-group">
      <img className="court-image" src={imageUrl} alt={name} onClick={handleBookNow}/>
      <div className="court-name">{name}</div>
      <div className='court-desc'>{desc}</div>
      <button className="submit-button" onClick={handleBookNow}>Book Now</button>
      <Modal isOpen={isOpen} onClose={toggleModal} images={courtImages} />
    </div>
  );
};

const Courts = () => {
  return (
    <div>
      <div className="courts-container">
        {courtsData.map(court => (
          <Court key={court.id} id={court.id} name={court.location} imageUrl={court.imageUrl} courtImages={court.courtImages} desc={court.desc} />
        ))}
      </div>
      <ContactUs />
    </div>
  );
};

export default Courts;
