import React, { useState, useEffect, useRef } from 'react';
import './Modal.css'; // Import CSS for styling
import Form from '../FormComponent/Form';

const Modal = ({ isOpen, onClose, images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState(null);
  const [showForm, setShowForm] = useState(false); // State to track if form should be displayed
  const formRef = useRef(null); // Reference for the form container

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling on the main page when modal is open
    } else {
      document.body.style.overflow = 'auto'; // Allow scrolling on the main page when modal is closed
    }
    return () => {
      document.body.style.overflow = 'auto'; // Reset body overflow style when component unmounts
    };
  }, [isOpen]);

  const nextSlide = () => {
    setSlideDirection('next');
    setTimeout(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
      setSlideDirection(null);
    }, 300); // Animation duration
  };

  const prevSlide = () => {
    setSlideDirection('prev');
    setTimeout(() => {
      setCurrentImageIndex((currentImageIndex + images.length - 1) % images.length);
      setSlideDirection(null);
    }, 300); // Animation duration
  };

  // Function to scroll the form into view smoothly
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (!isOpen) return null;

  console.log("Current image index:", currentImageIndex);
  console.log("Current image URL:", images[currentImageIndex]);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="modal-close" onClick={onClose}>&times;</span>
        <br></br>
        <br></br>
        <div className={`modal-slideshow ${slideDirection}`}>
          <img src={images[currentImageIndex]} alt={`Court ${currentImageIndex + 1}`} className="modal-slide" />
          <button className="modal-prev" onClick={prevSlide}>&#10094;</button>
          <button className="modal-next" onClick={nextSlide}>&#10095;</button>
        </div>
        <div className="modal-details">
          <h2>Location:</h2>
          <p>Redwood City, CA</p>
          <h2>Capacity:</h2>
          <p>200</p>
          <h2>Price:</h2>
          <p>$100/hr</p>
          <h2>Number of Courts:</h2>
          <p>2 full courts, 1 half court</p>
          <h2>Features:</h2>
          <p>Has seating for family and audience to come.</p>
          <h2>Parking:</h2>
          <p>Parking is available in the parking lot.</p>
        </div>
        {!showForm ? (
          <div className="submit-button-container">
            <button className="submit-button" onClick={() => { setShowForm(true); scrollToForm(); }}>Book Now 👇🏼</button>
          </div>
        ) : ( 
          <div ref={formRef}>
            <Form />
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
