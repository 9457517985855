import React from 'react';
import './Header.css'; // Import CSS for styling
import { useNavigate } from 'react-router-dom';

const Header = ({ isModalOpen }) => {

  const navigate = useNavigate();


  const navHome = (event) => {
    event.stopPropagation();
    navigate(`/`);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (isModalOpen) {
    return null;
  }

  return (
    <nav className="navbar">
      <div className="logo" onClick={scrollToTop}>
        <h1 onClick={navHome}>Ball Indoor 🏀</h1>
      </div>
    </nav>
  );
};

export default Header;
