import React from 'react';
import './CallNow.css';

const CallNow = () => {
  return (
    <div className="call-now">
      BOOK A NICE INDOOR BASKETBALL COURT! <br></br>CALL/TEXT: <br></br><a href="tel:510-413-8265">510-413-8265</a>
    </div>
  );
};

export default CallNow;
