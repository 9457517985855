import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import './FindCourtsPage.css';

import one from '../one.JPG';
import two from '../two.JPG';
import three from '../three.JPG';

const FindCourtsPage = () => {
  useEffect(() => {
    document.title = "Find the Best Indoor Basketball Courts Near You | BallIndoor.com";
  }, []);
  const navigate = useNavigate();
  const handleBookNow = (event, id) => {
    event.stopPropagation();
    navigate(`/`);
  };

  return (
    <div className="find-courts-page">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Discover the best indoor basketball courts near you. Read reviews, find availability, and book your favorite courts online." />
        <meta name="keywords" content="indoor basketball courts, basketball court finder, book basketball courts, basketball courts near me" />
        <meta name="author" content="BallIndoor" />
        <link rel="canonical" href="https://www.ballindoor.com/find-courts" />
      </Helmet>

      <header className="header">
        <h1>Find the Best Indoor Basketball Courts Near You</h1>
        <p>Your go-to platform for discovering top indoor basketball courts. Whether you're looking for a place to play with friends or practice for your next game, BallIndoor has you covered. With detailed court descriptions, high-quality images, and real player reviews, you’ll easily find the perfect court near you.</p>
      </header>

      <section className="featured-courts">
        <h2>Featured Courts</h2>
        <div className="courts-list">
          <div className="court-item">
            <img src={one} alt="Downtown Sports Center court with professional setup and excellent lighting" onClick={handleBookNow} />
            <h3>Downtown Sports Center</h3>
            <p>Located in the heart of the city, Downtown Sports Center offers premium indoor basketball courts with top-notch facilities. Enjoy well-maintained courts and a vibrant basketball community.</p>
            <button onClick={handleBookNow}>View Details</button>
          </div>
          <div className="court-item">
            <img src={two} alt="Midtown Athletic Club's spacious basketball court with a modern design" onClick={handleBookNow}/>
            <h3>Midtown Athletic Club</h3>
            <p>Experience the best indoor courts at Midtown Athletic Club, perfect for casual games or competitive matches. With state-of-the-art facilities and friendly staff, it's a favorite among locals.</p>
            <button onClick={handleBookNow}>View Details</button>
          </div>
          <div className="court-item">
            <img src={three} alt="Hoop Nation indoor basketball court featuring high-quality wooden flooring" onClick={handleBookNow}/>
            <h3>Hoop Nation</h3>
            <p>Hoop Nation is known for its high-quality courts and vibrant basketball scene. Whether you're a beginner or a pro, you'll find the perfect environment to hone your skills.</p>
            <button onClick={handleBookNow}>View Details</button>
          </div>
        </div>
      </section>

      <section className="why-choose-us">
        <h2>Why Choose BallIndoor?</h2>
        <p>At BallIndoor, we believe in making basketball accessible to everyone. Our platform is designed to help players of all skill levels find the best indoor courts in their area. Whether you're looking to book a court for a casual game or preparing for a tournament, we provide the tools and information you need to make the right choice.</p>
        <p>Our curated list of indoor basketball courts includes detailed descriptions, high-resolution images, and reviews from other players. You can also check court availability in real-time and book your spot in just a few clicks.</p>
      </section>

      <section className="reviews">
        <h2>What Players Are Saying</h2>
        <div className="review-item">
          <p>"The best place to play in town! The courts are always in great condition, and booking is super easy." - John D.</p>
        </div>
        <div className="review-item">
          <p>"Love the variety of courts available. There's always something nearby, and the amenities are fantastic!" - Sarah K.</p>
        </div>
        <div className="review-item">
          <p>"BallIndoor makes it so easy to find and book courts. I can see why so many players recommend it." - Michael B.</p>
        </div>
      </section>

      <footer className="footer">
        <p>&copy; 2024 BallIndoor. All rights reserved.</p>
        <p><a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-service">Terms of Service</a></p>
      </footer>
    </div>
  );
};

export default FindCourtsPage;
