import { Route, Routes } from 'react-router-dom';
import Header from './components/HeaderComponent/Header';
import Courts from './components/CourtComponent/Courts';
import CourtDetails from './components/CourtDetailComponent/CourtDetails';
import FindCourtsPage from './components/ContentComponent/FindCourtsPage';
import CallNow from './components/CallNowComponent/CallNow';

function App() {
  return (
    <>
      {/* <Header /> */}
      <Routes>
        <Route path="/findcourt" element={<FindCourtsPage />} />
        <Route path="/" element={<CallNow />} />
        <Route path="/sk" element={<Courts />} />
        <Route path="/court-details/:id" element={<CourtDetails />} />
      </Routes>
    </>
  );
}

export default App;
